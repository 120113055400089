<template>
<v-row>
    <v-col>
        <s-toolbar label="Soporte Usuarios" />
        <v-card :loading="loading" outlined>

            <v-card-title primary-title>
                <h3>Soporte SIGEIN </h3>
                           
            </v-card-title>
            <v-divider horizontal></v-divider>

            <v-card-text>

                <v-row>
                    <v-col cols="12" lg="4" md="4">
                        <h2>Expiration  Password SIGEIN</h2>
                        <p class="mt-2">
                            Actualizar Fecha Cambio Contraseña.
                        </p>
                        <v-row>
                            <v-col cols="6" lg="12" md="12">
                                <s-select :items="listUser" autocomplete full itemText="C005Login" itemValue="C005Login" v-model="C005Login"></s-select>
                            </v-col>
                            <v-col cols="12" lg="6" md="6">
                                <v-btn elevation="0" small color="info" @click="updateDateExpiration()">Actualizar</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>


                </v-row>

                <v-divider horizontal class="mt-6 mb-6"></v-divider>

                
            </v-card-text>
        </v-card>

        <v-btn @click="activedirectory()">Consultar active directory</v-btn>
    </v-col>

    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="500">
        <v-card :color="'#5175af'" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere...
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error... <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-row>
</template>

<script>
import _sOtherSupport from "@/services/Security/OtherSupportSigeinService.js";
import SSelect from '../../components/Utils/SSelect.vue';

export default {
  components: { SSelect },
    data() {
        return {
            processing: false,
            messageProcessing: "",
            loading: false,
            listUser: [],
            C005Login: null
        }
    },
    created(){
        this.getUser()
    },
    methods: {

        getUser(){
            _sOtherSupport.list(this.$fun.getUserID()).then(resp => {
                console.log(resp)
                if(resp.status == 200){
                    this.listUser = resp.data
                }
            })
        },

        updateDateExpiration(){
            
            if(this.C005Login == null){
                this.$fun.alert("Seleccione un usuario", "warning")
                return
            }


            var filter ={}
            filter = {
                C005Login: this.C005Login
            }

            this.$fun.alert("Seguro de continuar", "question").then(val => {
                if(val.value){
                    this.loading = true;
                    this.messageProcessing = ""
                    this.processing = true
                    _sOtherSupport.updateDateExpiration(filter, this.$fun.getUserID()).then(resp => {
                        if(resp.status == 200){
                            this.$fun.alert("Operacion completada correctamente", "success")
                            this.processing = false
                            this.loading = false;
                        }
                        
                    },(e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                            this.loading = false;
                    });
                        }
            })

            
        },

        activedirectory(){
             _sOtherSupport.searchuseractive("irvin.panta",this.$fun.getUserID()).then(resp => {
                console.log(resp)
                if(resp.status == 200){
                    console.log("activedirectory", resp.data)
                }
            })
        }
    },
};
</script>
