import Service from "../Service";

const resource = "othersupportsigein/";

export default {

    updateDateExpiration(obj, requestID) {
        console.log("service", obj)
        return Service.post(resource + "updateuserdatepass", obj, {
            params: { requestID: requestID },
        });
    },

    list(requestID) {
        return Service.post(
            resource + "listUser", {}, {
                params: { requestID: requestID },
            }
        );
    },


    activedirectory(requestID) {
        return Service.post(
            "othersupport/activedirectory/" + "allusers", {}, {
                params: { requestID: requestID },
            }
        );
    },

searchuseractive(username, requestID) {
        return Service.post(
            "othersupport/activedirectory/" + "searchuser", {}, {
                params: { username:username, requestID: requestID },
            }
        );
    },
};